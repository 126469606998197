<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title></v-toolbar-title>
      <div style="width: 30%; margin-left: -17px;">
        <v-text-field style="width: 95%;" 
          outlined v-model="filtroNumeroDocumento" 
          class="me-2" 
          label="Numero documento" dense>
        </v-text-field>
      </div>
      <div style="width: 30%;">
        <v-autocomplete 
          style="width: 95%;" 
          v-model="estadoFiltro" 
          class="me-2" 
          label="Estado" 
          :items="listaEstados" 
          dense outlined 
          item-text="text" 
          item-value="value"
        />
      </div>
      <!-- Botón para limpiar los filtros -->
      <v-tooltip style="width: 10%; margin-left: -10px" left color="success" class="me-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn  @click="limpiarFiltros" 
            small class="ms-2" 
            fab color="success" 
            v-bind="attrs"
            v-on="on"
            style="margin-top: -6px">
            <v-icon> restart_alt </v-icon>
          </v-btn>
        </template>
        <span>Restaurar filtros</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Modal Crear auditoria -->
      <v-dialog v-model="dialogCrearAuditoria" max-width="1200px">
        <template v-slot:activator="{ }">
          <v-tooltip style="width: 10%; margin-left: -10px" left color="success" class="me-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @mousedown.prevent="dialogCrearAuditoria = true" small class="ms-2" fab color="success" v-bind="attrs"
                v-on="on">
                <v-icon> add </v-icon>
              </v-btn>
            </template>
            <span>Crear nueva auditoria</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
            <div style="display: flex; justify-content: space-between; width: 100%;  ">
              <v-toolbar-title class="mt-2">Crear auditoria de paciente</v-toolbar-title>
              <v-btn icon dark @click="dialogCrearAuditoria = false">
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </v-toolbar>
          <v-card-text style="margin-top: 20px">
            <v-form>
                  <v-row>
                    <v-col cols="12" md="3">                              
                          <v-select 
                            style="max-width: 100%; " 
                            label="RIA atención" 
                            v-model="formNuevaAuditoria.riaAtencion"  
                            :items="listaRutas"
                            return-object 
                            :item-text="mostrarRutas" 
                            outlined dense>
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field 
                            v-model="formNuevaAuditoria.nombreAuditor" 
                            label="Nombre auditor"
                            dense 
                            outlined 
                            readonly>
                           </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            v-model="formNuevaAuditoria.ips"
                            :items="listaIps"
                            return-object
                            :item-text="mostrarListaIps"
                            item-value="id"
                            label="IPS Hospitalización"
                            :loading="cargandoIps"
                            :disabled="cargandoIps"
                            clearable dense outlined
                          ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-menu ref="menuPeriodoCarga" v-model="menuFechaCargaVisible" :close-on-content-click="false" transition="scale-transition" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- <validation-provider :rules="{ required: true }"> -->
                                  <v-text-field 
                                    v-model="formNuevaAuditoria.fechaCarga"
                                    label="Fecha Ingreso Hospitalización"
                                    readonly 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    dense outlined
                                    class="full-width">
                                  </v-text-field>
                                <!-- </validation-provider> -->
                              </template>
                            <v-date-picker 
                              v-model="formNuevaAuditoria.fechaCarga" 
                              :active-picker.sync="activePicker"
                              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              min="1950-01-01" 
                              locale="es-co" 
                              :first-day-of-week="1"
                              @change="savePeriodoCargaVisible">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- Mostrar el mes calculado en este campo -->
                        <v-col cols="12" md="3">
                          <v-text-field 
                            v-model="formNuevaAuditoria.mesIngreso"
                            label="Mes ingreso" 
                            dense outlined readonly>
                          </v-text-field>
                        </v-col>

                <v-col cols="12" md="3">
                  <!-- v-autocomplete para Tipo Documento -->
                  <v-autocomplete
                    v-model="formNuevaAuditoria.tipoDocumento"
                    :items="listaTipoDocumento" 
                    return-object 
                    :item-text="mostrarListaTipoDocumentos" 
                    item-value="id"
                    label="Tipo documento"
                    clearable dense outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field 
                    :disabled="(formNuevaAuditoria.tipoDocumento === '')" 
                    label="Número Documento"
                    v-model="formNuevaAuditoria.numeroDocumento"
                    :counter="20" 
                    dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                      v-model="formNuevaAuditoria.pacienteCohorte"
                      :items="listaCohorte" readonly
                      label="Pertenece a la COHORTE"
                      dense outlined
                  ></v-select>
                </v-col>
              <!-- Datos del Paciente -->
                <v-col cols="12" md="3">
                  <v-text-field ref="primerNombre" 
                    label="Primer Nombre"
                    v-model="formNuevaAuditoria.primerNombre" 
                    dense outlined readonly> 
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field ref="segundoNombre" 
                    label="Segundo Nombre"
                    v-model="formNuevaAuditoria.segundoNombre" 
                    dense outlined readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field ref="primerApellido" 
                    label="Primer Apellido"
                    v-model="formNuevaAuditoria.primerApellido" 
                    dense outlined readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field ref="segundoApellido" 
                    label="Segundo Apellido"
                    v-model="formNuevaAuditoria.segundoApellido" 
                    dense outlined readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field 
                    label="Genero"
                    v-model="formNuevaAuditoria.genero"
                    clearable dense outlined readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field 
                    v-model="formNuevaAuditoria.edad"
                    label="Edad"
                    dense outlined readonly>
                  </v-text-field >
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete style="width: 95%;" 
                    v-model="formNuevaAuditoria.municipio"
                    :items="listaMunicipios"
                    dense outlined 
                    return-object 
                    :item-text="mostrarMunicipios" 
                    label="Municipio Residencia">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="formNuevaAuditoria.fechaIngresoCohorte"
                    label="Fecha ingreso COHORTE"
                    :loading="cargandoIps"
                    clearable dense outlined readonly>
                  </v-text-field>
                </v-col>  
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="formNuevaAuditoria.diagnosticoBaseCohorte"
                    label="Diagnostico base COHORTE"
                    dense outlined readonly>
                  </v-text-field>
                </v-col>       
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="formNuevaAuditoria.ipsAsignada"
                    label="IPS Asignada COHORTE"
                    :loading="cargandoIps"
                    clearable dense outlined readonly>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete 
                    @keyup="buscarGrupoCie($event.target.value)" 
                    style="width: 95%;" 
                    v-model="formNuevaAuditoria.diagnosticoHospitalizacion"
                    :items="listaGrupoDiagnostico" 
                    return-object 
                    :item-text="mostrarGrupoDiagnostico" 
                    dense outlined
                    label="Diagnostico hospitalización">
                  </v-autocomplete>
                </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      v-model="formNuevaAuditoria.riesgoCardiovascular"
                      :items="opcionesRiesgoCardiovascular"
                      label="Riesgo Cardiovascular?"
                      clearable dense outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formNuevaAuditoria.diagnosticoRiesgo"
                          :items="opcionesDiagnostico"
                          item-text="text"
                          label="Diagnóstico relacionado a riesgo cardiovascular"
                          clearable dense outlined
                          :disabled="!formNuevaAuditoria.riesgoCardiovascular"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formNuevaAuditoria.tipoIngreso"
                          :items="opcionesTipoIngreso"
                          label="Tipo Ingreso"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field 
                      v-model="formNuevaAuditoria.ipsRescate" 
                      label="IPS remisión/rescate" 
                      dense outlined 
                      :disabled="!formNuevaAuditoria.tipoIngreso || formNuevaAuditoria.tipoIngreso === 'Espontáneo'">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formNuevaAuditoria.inoportunidadRescate"
                          :items="opcionesSiNo"
                          label="Inoportunidad Rescate"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                        v-model="formNuevaAuditoria.idMotivoInoportunidad"
                        :items="opcionesInoportunidadRescate"
                        item-text="descripcionMotivo"
                        item-value="idMoin"
                        label="Inoportunidad Rescate"
                        clearable dense outlined
                        :loading="cargandoMotivos"
                        :disabled="!formNuevaAuditoria.inoportunidadRescate"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                      v-model="formNuevaAuditoria.reingreso"
                      :items="opcionesSiNoHospitalizacion"
                      label="Reingreso hospitalización < 15 días"
                      clearable dense outlined
                      ></v-autocomplete>     
                  </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogCrearAuditoria = false">CERRAR</v-btn>
            <v-btn depressed color="success" text @click="guardarNuevaAuditoria()">Guardar auditoria</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- Tabla de Auditoría de pacientes -->
    <v-data-table
      :headers="headers"
      :items="auditoriaPacientes"
      item-value="name"
      class="tableResults mt-0"
      height="59vh"
      loading-text="Cargando..."
      :footer-props="footerProps"
      :items-per-page="itemsPorPagina"
      :server-items-length="totalElementos"
      @update:items-per-page="(i) => itemsPorPagina = i"
      @update:page="(p) => pagina = p"
      fixed-header
      :loading="animacionTablaMaestra"
    >
      <template v-slot:item.acciones="{ item }">
        <v-btn text icon color="green" @click="abrirModalCrearObservacion(item)">
          <v-icon>post_add</v-icon>
        </v-btn>
        <v-btn text icon color="blue" @click="abrirModalVerObservaciones(item)">
          <v-icon>note_add</v-icon>
        </v-btn>
        <v-btn text icon color="green" @click="abrirModalCerrarAuditoria(item)" :disabled="item.estado === 2">
          <v-icon>check</v-icon>
        </v-btn>
      </template>
    </v-data-table>


    <!-- Modal para mostrar observaciones -->
    <v-dialog v-model="dialogVerObservaciones" max-width="1200px">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;">
            <v-toolbar-title class="mt-2">Detalle de Auditoría</v-toolbar-title>
            <v-btn icon dark @click="dialogVerObservaciones = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text style="margin-top: 20px">
          <!-- Card para mostrar información de auditoría -->
          <v-card>
            <v-card-text>
              <v-row>
                <!-- Primera columna -->
                <v-col cols="6">
                  <div><strong>COHORTE:</strong> {{ informacionAuditoria.cohorte ? 'Sí' : 'No' }}</div>
                  <div><strong>FECHA INGRESO IPS:</strong> {{ new Date(informacionAuditoria.fechaIngresoIps).toLocaleDateString() }}</div>
                  <div><strong>IPS ORIGEN:</strong> {{ informacionAuditoria.ipsOrigen }}</div>
                  <div><strong>IPS ASIGNADA:</strong> {{ informacionAuditoria.ipsAsignada }}</div>
                  <div><strong>DIAGNOSTICO HOSPITALIZACIÓN:</strong> {{ informacionAuditoria.descripcionDiagnostico }}</div>
                  <div><strong>RIESGO CARDIOVASCULAR:</strong> {{ informacionAuditoria.riesgoCardiovascular ? 'Sí' : 'No' }}</div>
                  <div><strong>DIAGNOSTICO CARDIOVASCULAR:</strong> {{ informacionAuditoria.diagnosticoCardiovascular }}</div>
                </v-col>

                <!-- Segunda columna -->
                <v-col cols="6">
                  <div><strong>FECHA INGRESO COHORTE:</strong> {{ informacionAuditoria.fechaIngresoCohorte }}</div>
                  <div><strong>DIAGNOSTICO BASE COHORTE:</strong> {{ informacionAuditoria.descripcionDiagnosticoSecundario }}</div>
                  <div><strong>TIPO INGRESO:</strong> {{ informacionAuditoria.tipoIngresoDesc }}</div>
                  <div><strong>IPS REMISIÓN/RESCATE:</strong> {{ informacionAuditoria.ipsRescate }}</div>
                  <div><strong>INOPORTUNIDAD RESCATE:</strong> {{ informacionAuditoria.inoportunidadRescate ? 'Sí' : 'No' }}</div>
                  <div><strong>MOTIVO INOPORTUNIDAD RESCATE:</strong> {{ informacionAuditoria.motivoInoportunidad }}</div>
                  <div><strong>REINGRESO HOSPITALIZACIÓN MENOR A 15 DÍAS:</strong> {{ informacionAuditoria.reingreso ? 'Sí' : 'No' }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Mostrar los anexos en un v-card -->
          <div v-if="anexosAuditoria && anexosAuditoria.length">
            <v-divider></v-divider>
            <v-card class="mt-4" v-for="(anexo, index) in anexosAuditoria" :key="index">
              <v-card-text style="background: aliceblue; padding: 5px">
                <v-btn :href="anexo.rutaArchivo + anexo.nombreArchivo" target="_blank" color="#0d47a1" text>
                  Ver Historia clínica
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
          <!-- Segunda tabla para mostrar el seguimiento de auditoría -->
          <div style="margin-top: 16px"> 
            <v-simple-table class="tableResults mt-0" style="margin-top: 20px;">
              <thead>
                <tr>
                  <th>ESTADO AUDITORIA</th>
                  <th>OBSERVACIÓN</th>
                  <th>ELIMINADO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(seguimiento, index) in segumientoAuditoria" :key="index">
                  <td>{{ traducirEstadoAuditoria(seguimiento.estadoAuditoria) }}</td>
                  <td>{{ seguimiento.observacion }}</td>
                  <td>{{ seguimiento.eliminado ? 'Sí' : 'No' }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogVerObservaciones = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para cerrar auditoría -->
    <v-dialog v-model="dialogCerrarAuditoria" max-width="1200px">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Cerrar Auditoria</v-toolbar-title>
            <v-btn icon dark @click="dialogCerrarAuditoria = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text style="margin-top: 20px">
          <v-form>
              <v-row>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.tipoEstancia"
                          :items="tipoEstanciaOptions"
                          label="Tipo de estancia"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu ref="menuPeriodoCargaFechaEgresoIps" v-model="menuFechaCargaVisibleFechaEgresoIps" :close-on-content-click="false" transition="scale-transition" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                      <!-- <validation-provider :rules="{ required: true }"> -->
                        <v-text-field 
                          v-model="formCerrarAuditoria.fechaEgresoIps"
                          label="Fecha egreso de ips/ese"
                          readonly 
                          v-bind="attrs" 
                          v-on="on" 
                          dense outlined
                          class="full-width">
                        </v-text-field>
                        <!-- </validation-provider> -->
                        </template>
                        <v-date-picker 
                          v-model="formCerrarAuditoria.fechaEgresoIps" 
                          :active-picker.sync="activePicker"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          min="1950-01-01" 
                          locale="es-co" 
                          :first-day-of-week="1"
                          @change="savePeriodoCargaVisibleFechaEgresoIps">
                        </v-date-picker>
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select 
                      v-model="formCerrarAuditoria.diasHospitalizacion"
                      :items="formCerrarAuditoria.diasHospitalizacionOptions"
                      label="Dias hospitalización"
                      dense
                      outlined>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field 
                      v-model="formCerrarAuditoria.mesEgreso"
                      label="Mes egreso" 
                      dense 
                      outlined>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.diasInoportunidad"
                          :items="formCerrarAuditoria.diasInoportunidadOptions"
                          label="Días inoportunidad"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>       
                  <v-col cols="12" md="3">
                    <v-autocomplete
                        v-model="formCerrarAuditoria.motivoInoportunidad"
                        label="Motivo inoportunidad"
                        :items="motivoInoportunidadOptions"
                        clearable dense outlined                    
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.condicionEgreso"
                          :items="condicionEgresoOptions"
                          label="Condición egreso del paciente"
                          clearable dense outlined
                           @change="updateDestinoPacienteOptions"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.destinoPaciente"
                          :items="filteredDestinoAfiliadoOptions"
                          label="Destino del paciente"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      v-model="formCerrarAuditoria.sitioRemision"
                      label="Sitio de remisión"
                      :items="listaIps"
                      return-object
                      :item-text="mostrarListaIps"
                      item-value="id"
                      dense outlined>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                      v-model="formCerrarAuditoria.facturaPatologia"
                      :items="facturaPatologiaOptions"
                      label="Factura susceptible patología base"
                      clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                        v-model="formCerrarAuditoria.idMotivoHospitalizacion"
                        :items="formCerrarAuditoria.opcionesMotivoHospitalizacion"
                        item-text="descripcionMotivo"
                        item-value="idMoho"
                        label="Motivo hospitalización patología base"
                        clearable dense outlined
                        :loading="cargandoMotivos"                     
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                        v-model="formCerrarAuditoria.idMotivoUT"
                        :items="formCerrarAuditoria.opcionesMotivoUt"
                        item-text="descripcionMotivo"
                        item-value="idMoin"
                        label="Motivo descompensación no a cargo UT"
                        clearable dense outlined
                        :loading="cargandoMotivos"                     
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field 
                      v-model="formCerrarAuditoria.valorCargoUt"
                      label="Valor hospitalizacion a cargo de la UT" 
                      dense 
                      outlined>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.reporteEventoAdversoSiNo"
                          :items="reporteEventoAdversoOptions"
                          label="Reporte evento adverso?"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field 
                      v-model="formCerrarAuditoria.eventoAdverso"
                      label="Evento adverso" 
                      dense outlined
                      :disabled="!formCerrarAuditoria.reporteEventoAdversoSiNo || formCerrarAuditoria.reporteEventoAdversoSiNo === 'No'">
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                        v-model="formCerrarAuditoria.infeccion"
                        :items="infeccionOptions"
                        label="Infecciones relacionadas cuidado salud"
                        clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="formCerrarAuditoria.tasaMortalidad"
                      label="Tasa de mortalidad intrahospitalaria" 
                      dense outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.polimedicado"
                          :items="pacientePolimedicadoOptions"
                          label="Paciente polimedicado"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="formCerrarAuditoria.conciliacionMedicamento"
                          :items="conciliacionMedicamentosaOptions"
                          label="Conciliación medicamentosa"
                          clearable dense outlined
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu ref="menuPeriodoCargaFechaCitaPreHospitalaria" v-model="menuFechaCargaVisibleFechaCitaPreHospitalaria" :close-on-content-click="false" transition="scale-transition" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          v-model="formCerrarAuditoria.fechaCitaPrehospitalaria"
                          label="Fecha cita post hospitalaria"
                          readonly 
                          v-bind="attrs" 
                          v-on="on" 
                          dense outlined
                          class="full-width">
                        </v-text-field>
                      </template>

                      <v-date-picker 
                        v-model="formCerrarAuditoria.fechaCitaPrehospitalaria" 
                        :active-picker.sync="activePicker"
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" 
                        locale="es-co" 
                        :first-day-of-week="1"
                        @change="savePeriodoCargaVisibleFechaCitaPreHospitalaria">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
              </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogCerrarAuditoria = false">CERRAR</v-btn>
          <v-btn color="success" text @click="cerrarAuditoria()">Cerrar Auditoría</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para agregar observación -->
    <v-dialog v-model="dialogCrearObservacion" max-width="700px">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Agregar seguimiento a la auditoria {{ tipoDoc }} {{ numDoc }}</v-toolbar-title>
            <v-btn icon dark @click="dialogCrearObservacion = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text style="margin-top: 20px">
          <v-form>
            <v-textarea label="Seguimiento a la auditoría" v-model="observacion" required dense outlined></v-textarea>
            <v-file-input label="Historia clínica" v-model="historiaClinica" accept=".pdf" required dense outlined></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogCrearObservacion = false">CERRAR</v-btn>
          <v-btn depressed color="success" text @click="guardarObservacion()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Notificación en caso de que afiliado no pertenezca a la Cohorte-->
    <v-snackbar v-model="mostrarNotificacion" color="error" :timeout="3000">
      El número de documento no corresponde a un afiliado perteneciente a la Cohorte de la Ruta.
    </v-snackbar>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" top>
      {{ snackbarMessage }}
    </v-snackbar>   

  </v-container>
</template>

<script>

import { mapState } from "vuex";
import moment from 'moment';


const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {

  computed: {
    ...mapState(["auth", "enterprise"]),
  },

  created() {
    this.userRoles = this.auth.roles;
    this.cargarListaRutas();
    this.getAuditorias();
    this.getListaPrestadores();
    this.getTipoDocumentos();
    this.getMotivosInoportunidad();
    this.getListaMunicipios();
    this.getListaGrupoDiagnostico('');
    this.getMotivosHospitalizacion();
    this.getMotivosUt();
  },

  watch: {
    "formNuevaAuditoria.numeroDocumento": debounce(function () {
      this.llenarCamposAfiliado();
      this.llenarCamposInformacionCohorte();
    }, 500),
    "itemsPorPagina": function () { this.getAuditorias(); },
    "pagina": function () { this.getAuditorias() },
    "filtroNumeroDocumento": debounce(function () { 
      this.getAuditorias();
    }, 500),
    "estadoFiltro": function () { this.getAuditorias()},
    // Monitorea cambios en listaRutas y asigna automáticamente si hay solo una opción
    listaRutas(newVal) {
      if (newVal.length === 1) {
        this.formNuevaAuditoria.riaAtencion = newVal[0];
      }
    },

  },
   

  data() {
    return {

      // Tabla ppal
      headers: [
        { text: 'AUDITORIA No.', value: 'idAuditoria', sortable: false },
        { text: 'TIPO DOCUMENTO', value: 'tipoDocumento', sortable: false },
        { text: 'DOCUMENTO', value: 'numeroDocumento', sortable: false },
        { text: 'AFILIADO', value: 'nombresApellidos', sortable: false },
        { text: 'GÉNERO', value: 'genero', sortable: false },
        { text: 'EDAD', value: 'edad', sortable: false },
        { text: 'ESTADO', value: 'estadoDescripcion', sortable: false },
        { text: 'MUNICIPIO RESIDENCIA', value: 'municipioResidencia', sortable: false },
        { text: 'COHORTE', value: 'cohorte', sortable: false },
        { text: 'IPS ASIGNADA', value: 'ipsAsignada', sortable: false },
        { text: 'ACCIONES', value: 'acciones', sortable: false,  width: '150px' },
      ],
      
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'error', // Color inicial del snackbar
      timeout: 6000, // Tiempo en milisegundos que la notificación estará visible

      usuario: "",

      // crear-auditoria
      dialogCrearAuditoria: false,
      auditoriaPacientes: [],
      ipsList: [],
      ipsSeleccionada: null,
      cargandoIps: false,

      listaRutas:[],

      listaCohorte: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],
  
      opcionesRiesgoCardiovascular:  [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],
      diagnosticoRiesgo: null,
      opcionesDiagnostico: [
        { text: "Hipertensión", value: "H" },
        { text: "Diabetes", value: "D" },
        { text: "EPOC", value: "E" }
      ],

      tipoIngreso: null,
      opcionesTipoIngreso: ['Espontáneo', 'Remisión', 'Rescate'],

      inoportunidadRescate: null,
      opcionesSiNo: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      motivoInoportunidadRescate : "",
      opcionesInoportunidadRescate : [],
      cargandoMotivos: false,

      reingresoHospitalizacion: '',
      opcionesSiNoHospitalizacion: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      // Para Cerrar Auditoria: 

      tipoEstancia: '',
      tipoEstanciaOptions: [
        { text: "Hospitalaria", value: "H" },
        { text: "Quirúrgica", value: "Q" },
        { text: "Urgencias", value: "U" }    
      ],

      diasInoportunidad: '',
      diasInoportunidadOptions: Array.from({ length: 20 }, (_, i) => i + 1),

      opcionesInoportunidad: [
        { text: "Opcion 1", value: 1 },
        { text: "Opcion 2", value: 2 }
      ],

      condicionEgreso: '',
      condicionEgresoOptions: [
        { text: "Vivo", value: true },
        { text: "Fallecido", value: false }
      ],
      destinoAfiliadoOptions: [
          { text:'Remisión', value: 'R' },
          { text:'Contraremisión', value: 'CR' },
          { text:'Domicilio', value: 'H' },
          { text:'Fallecido < 48 horas', value: 'LD' },
          { text:'Fallecido > 48 horas', value: 'HD' },
          { text:'Muerte perinatal', value: 'PD' }
      ],
      filteredDestinoAfiliadoOptions: [], // Aquí almacenaremos las opciones filtradas

      motivoInoportunidadOptions: [
        "Inoportunidad ayuda diagnóstica",
        "Reprogramación quirurgica",
        "Inoportunidad interconsulta",
        "Demora tramites administrativos"
      ],

      facturaPatologiaOptions : [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      reporteEventoAdversoOptions : [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      infeccionOptions : [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      pacientePolimedicadoOptions: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],

      conciliacionMedicamentosaOptions: [
        { text: "SI", value: true },
        { text: "NO", value: false }
      ],
      
      listaIps: Array,
      listaTipoDocumento: Array,
      listaMunicipios: Array,
      listaGrupoDiagnostico: [],

      activePicker: null, // Propiedad para el active-picker.sync
      menuFechaCargaVisible: false, // Propiedad booleana para controlar la visibilidad del menú
      menuFechaCargaVisibleFechaEgresoIps: false,
      menuFechaCargaVisibleFechaCitaPreHospitalaria: false,

      respuestaValidarAfiliado: [],
      respuestaValidarInformacionCohorte: [],
      habilitarCamposAfiliados: false,
      mostrarNotificacion: false,
      evitarLlenarCampos: false, 
      // v-model precarga campos model formulario crear auditoria
      formNuevaAuditoria: {
          
          mesIngreso: "",
          tipoDocumento: "",
          numeroDocumento: "",
          primerNombre : "",
          segundoNombre : "",
          primerApellido : "",
          segundoApellido : "",
          genero: "",
          edad: "",
          edadOptions: Array.from({ length: 110 }, (_, i) => i + 1),
          municipio: "",
          nombreAuditor: "",

        tipoIngreso: "",
        pacienteCohorte: "",
        fechaCarga: null, 
        ips: "",
        ipsAsignada: "",
        riesgoCardiovascular: "", 
        diagnosticoRiesgo: null, // no requerido
        ipsRescate: null, 
        inoportunidadRescate: "",
        idMotivoInoportunidad: null, // no requerido
        reingreso: "",
        idAfiliado : "",
        diagnosticoHospitalizacion: "",
        diagnosticoSecundario: "",
        diagnosticoBaseCohorte: "",
        riaAtencion: "",
        fechaIngresoCohorte: ""
      },

      objetoFinal: {
        tipoIngreso               : "",   // string
        cohorte                   : "",   // boolean
        fechaIngresoIps           : "",   // Date
        ipsOrigen                 : "",   // number
        riesgoCardiovascular      : "",   // boolean
        ipsRescate                : null, // string
        inoportunidadRescate      : "",   // boolean
        idMotivoInoportunidad     : null, // number
        reingreso                 : "",   // boolean
        idAfiliado                : "",   // number
        idDiagnostico             : "",   // string
        diagnosticoCardiovascular : null, // string
        idRuta                    : "",   //  number

        ipsAsignada               : "",   // number
        idDiagnosticoSecundario   : "",   // string

      },
      
      // v-model precarga campos model formulario cerrar auditoria
      formCerrarAuditoria: {
        tipoEstancia  : "",
        fechaEgresoIps: "",
        diasHospitalizacion: "",
        diasHospitalizacionOptions : Array.from({ length: 110 }, (_, i) => i + 1),
        mesEgreso : "",
        diasInoportunidad : "",
        diasInoportunidadOptions : Array.from({ length: 110 }, (_, i) => i + 1),
        motivoInoportunidad: "",
        condicionEgreso: "",
        destinoPaciente: "",
        sitioRemision: "",
        facturaPatologia: "",
        idMotivoHospitalizacion: "",
        opcionesMotivoHospitalizacion : [], 
        idMotivoUT: "",
        opcionesMotivoUt : [], 
        valorCargoUt: "",
        reporteEventoAdversoSiNo:'',
        eventoAdverso: "",
        infeccion: "",
        tasaMortalidad: "",
        polimedicado: "",
        conciliacionMedicamento: "",
        fechaCitaPrehospitalaria: "",
      },

      objetoFinalCerrar : {
        idAuditoria: "",
        tipoEstancia: "",
        fechaEgresoIps: "",
        diasHospitalizacion: "",
        mesEgreso: "",
        diasInoportunidad: "",
        motivoInoportunidad: "",
        condicionEgreso: "",
        destinoPaciente: "",
        sitioRemision: "",
        facturaPatologia: "",
        idMotivoHospitalizacion: "",
        idMotivoUT: "",
        valorCargoUt: "",
        eventoAdverso: "",
        infeccion: "",
        tasaMortalidad: "",
        polimedicado: "",
        conciliacionMedicamento: "",
        fechaCitaPrehospitalaria: "",
      },

      //nueva-observacion
      dialogCrearObservacion: false,
      idAuditoriaSeleccionada: null,
      auditoriasConObservacion: [], // Para controlar auditorías que ya tienen observaciones
      idAuditoriaConcurrente: null,
      observacion: '',
      historiaClinica: null,

      formData: new FormData(),

      dialogVerObservaciones: false,
      observaciones : [],
      informacionAuditoria: {
        cohorte: null,
        fechaIngresoIps: null,
        ipsOrigen: "",
        ipsAsignada: "",
        diagnostico: "",
        descripcionDiagnostico: "",
        riesgoCardiovascular: null,
        diagnosticoCardiovascular: null,
        diagnosticoSecundario: "",
        descripcionDiagnosticoSecundario: "",
        tipoIngreso: null,
        tipoIngresoDesc: "",
        ipsRescate: null,
        inoportunidadRescate: null,
        motivoInoportunidad: "",
        reingreso: "",
        fechaIngresoCohorte: ""
      },
      segumientoAuditoria: [],
      numDoc: null,
      tipoDoc: null,
      anexosAuditoria: '',

      dialogCerrarAuditoria: false,

      // Paginación:
      itemsPorPagina: 10,
      pagina: 1,
      totalElementos: 0,
      animacionTablaMaestra: false,
      page: this.pagina - 1,
      size: this.itemsPorPagina,
      sort: 'idAuditoria,desc',
      footerProps: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },

      filtroNumeroDocumento: null,
      estadoFiltro: null,
      listaEstados: [
        { text: "Creado", value: 0 },
        { text: "En revisión", value: 1 },
        { text: "Cerrado", value: 2 }
      ],

    };
  },

  mounted() {

    this.getAuditorias(); 
    this.filteredDestinoAfiliadoOptions = [];
    this.formNuevaAuditoria.nombreAuditor = this.auth.username.toUpperCase();
  },

  methods: {

    /**
     * Método para cargar la lista de rutas en el componente
     * Descripción:
     * Este método invoca la función `getListaRutas` para obtener y cargar
     * la lista de rutas en el componente actual. Sirve como un alias para
     * `getListaRutas`
     */
    cargarListaRutas() {
      this.getListaRutas();
    },

    /**
     * Método para limpiar los filtros aplicados en el componente Vue.
     *
     * Descripción:
     * Este método restablece los filtros de búsqueda a sus valores predeterminados.
     * En concreto, establece `estadoFiltro` en `null` y `filtroNumeroDocumento` en
     * una cadena vacía, eliminando cualquier criterio de filtrado previamente aplicado.
     * Después de limpiar los filtros, llama a `getAuditorias` para recargar la lista
     * de auditorías sin filtros.
     *
     * Uso:
     * Puede utilizarse para reiniciar los filtros, por ejemplo, al hacer clic en un
     * botón de "Limpiar filtros".
     *
     * Ejemplo de uso:
     * this.limpiarFiltros();
     *
     * Efectos:
     * - `estadoFiltro`: Restablecido a `null`.
     * - `filtroNumeroDocumento`: Restablecido a una cadena vacía (`''`).
     * - Invoca `getAuditorias()` para recargar la lista de auditorías.
     *
     * @returns {void} No devuelve ningún valor.
     */
    limpiarFiltros() {
      this.estadoFiltro = null; // Restablece el filtro de estado
      this.filtroNumeroDocumento = ''; // Restablece el filtro de número de documento, si lo tiene
      this.getAuditorias();
    },

    /**
     * Método para obtener la lista de auditorías desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar una lista de auditorías
     * según los filtros y parámetros de paginación configurados. Los resultados se asignan
     * a la propiedad `auditoriaPacientes` del componente, y el total de elementos se guarda
     * en `totalElementos`.
     *
     * Parámetros de la solicitud:
     * - `page`: Número de la página, predeterminado a `0` (primer página).
     * - `size`: Tamaño de la página, predeterminado a `10`.
     * - `numeroDocumento`: Número de documento para filtrar, obtenido de `filtroNumeroDocumento`.
     * - `estado`: Estado de la auditoría para filtrar, obtenido de `estadoFiltro`.
     *
     * Transformación de Datos:
     * La respuesta se mapea en un formato específico, incluyendo campos como `idAuditoria`,
     * `numeroDocumento`, `tipoDocumento`, `nombresApellidos`, `genero`, `edad`, `municipioResidencia`,
     * `cohorte`, `ipsAsignada`, `estado`, y `estadoDescripcion`.
     *
     * Ejemplo de uso:
     * this.getAuditorias();
     *
     * Efectos:
     * - `totalElementos`: Se actualiza con el total de auditorías.
     * - `auditoriaPacientes`: Se actualiza con la lista de auditorías obtenida y transformada.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getAuditorias() {
      this.$http.get("msa-localapp/api/v1/auditoria-concurrente/listar-auditorias",
      { params: {
          page: 0, // this.pagina - 1,
          size: 10, // this.itemsPorPagina,
          numeroDocumento: this.filtroNumeroDocumento || '', 
          estado: this.estadoFiltro !== null ? this.estadoFiltro : undefined
        }
      }).then((result) => {
        this.totalElementos = result.data.totalElements;
        this.auditoriaPacientes = result.data.map((auditoria) => {  
          return {
            idAuditoria: auditoria.idAuditoria,
            numeroDocumento: auditoria.numeroDocumento,
            tipoDocumento: auditoria.tipoDocumento,
            nombresApellidos: auditoria.nombre,
            genero: auditoria.genero,
            edad: this.calcularEdad(auditoria.fechaNacimiento) ?? "",
            municipioResidencia: auditoria.ciudad,
            cohorte: 'SI',
            ipsAsignada: auditoria.ipsAsignada,
            estado: auditoria.estado,
            estadoDescripcion: auditoria.estadoDescripcion
          };
        });
      });
    },

    /**
     * Método para abrir el modal de creación de una auditoría.
     *
     * Descripción:
     * Este método habilita el cuadro de diálogo para crear una nueva auditoría, permitiendo al
     * usuario ingresar la información necesaria para registrar una auditoría. Si solo existe una
     * ruta en la lista (`listaRutas`), el campo `riaAtencion` del formulario `formNuevaAuditoria`
     * se preestablece automáticamente con esa única ruta, simplificando el proceso de creación.
     *
     * Lógica condicional:
     * - Si `listaRutas` contiene exactamente un elemento, se asigna automáticamente a
     *   `formNuevaAuditoria.riaAtencion`.
     *
     * Ejemplo de uso:
     * this.abrirModalCrearAuditoria();
     *
     * Efectos:
     * - `dialogCrearAuditoria`: Se establece en `true` para mostrar el modal de creación.
     * - `formNuevaAuditoria.riaAtencion`: Se asigna la única ruta de `listaRutas` si
     *   solo contiene un elemento.
     *
     * @returns {void} No devuelve ningún valor.
     */
    abrirModalCrearAuditoria() {
      this.dialogCrearAuditoria = true;
      if (this.listaRutas.length === 1) {
        this.formNuevaAuditoria.riaAtencion = this.listaRutas[0];
      }
    },

    /**
     * Método para obtener la lista de prestadores de salud (IPS) desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar la lista de
     * Instituciones Prestadoras de Salud (IPS). La lista recibida se asigna a la
     * propiedad `listaIps` del componente, permitiendo su uso en otras partes de
     * la interfaz (por ejemplo, en selectores o filtros).
     *
     * Ejemplo de uso:
     * this.getListaPrestadores();
     *
     * Efectos:
     * - `listaIps`: Se actualiza con la lista de IPS obtenida desde el servidor.
     * - La respuesta de la API se muestra en la consola para verificar el contenido recibido.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getListaPrestadores() {
      this.$http.get("msa-localapp/api/GetListaIPSPrestadores").then((result) => {
        console.log(result.data)
        this.listaIps = result.data;
      })
    },

    /**
     * Método para obtener la lista de rutas desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar la lista de rutas.
     * La respuesta se almacena en la propiedad `listaRutas` del componente, lo cual permite
     * que otras partes de la interfaz accedan a esta información (por ejemplo, en menús
     * desplegables o formularios que dependen de la selección de una ruta).
     *
     * Ejemplo de uso:
     * this.getListaRutas();
     *
     * Efectos:
     * - `listaRutas`: Se actualiza con la lista de rutas obtenida desde el servidor.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getListaRutas(){
      this.$http.get("msa-localapp/api/v1/ruta/informacion-rutas").then((result) => {
        this.listaRutas = result.data
      })
    },

    /**
     * Método para mostrar el nombre del prestador de salud (IPS) en la lista.
     *
     * Descripción:
     * Este método recibe un objeto `item` (que representa una IPS) y devuelve su
     * propiedad `nombre_prestador`, que es el nombre de la institución prestadora de
     * salud. Utiliza el operador de encadenamiento opcional (`?.`) para evitar errores
     * si `item` es nulo o indefinido.
     *
     * Parámetros:
     * - `item` (Object): Objeto que representa una IPS, debe contener la propiedad `nombre_prestador`.
     *
     * Ejemplo de uso:
     * this.mostrarListaIps(item);
     *
     * Retorno:
     * - {String|undefined} El nombre del prestador (`nombre_prestador`) si existe, de lo contrario `undefined`.
     */
    mostrarListaIps(item) {
      return item?.nombre_prestador;
    },

    /**
     * Método para obtener la lista de tipos de documentos desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar una lista de tipos de
     * documentos. La respuesta se asigna a la propiedad `listaTipoDocumento` del componente,
     * lo que permite su uso en otras partes de la interfaz, como selectores de tipo de
     * documento en formularios.
     *
     * Ejemplo de uso:
     * this.getTipoDocumentos();
     *
     * Efectos:
     * - `listaTipoDocumento`: Se actualiza con la lista de tipos de documentos obtenida desde el servidor.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getTipoDocumentos() {
      this.$http.get("msa-localapp/api/v1/tipo-documento").then((result) => {
        this.listaTipoDocumento = result.data;
      })
    },

    /**
     * Método para obtener el detalle de una auditoría específica desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para obtener los detalles de una
     * auditoría específica, usando el `idAuditoria` proporcionado como parámetro. Los
     * datos recibidos se procesan y se asignan a propiedades del componente para su
     * visualización en el UI. Si ocurre un error, se muestra un mensaje de error en la consola
     * y se invoca `showError` para informar al usuario.
     *
     * Parámetros:
     * - `idAuditoria` (Number): Identificador único de la auditoría que se quiere obtener.
     *
     * Ejemplo de uso:
     * this.getdetalleAuditoria(123);
     *
     * Efectos:
     * - `observaciones`: Se actualiza con las observaciones de la auditoría, o con un array vacío si no existen.
     * - `anexosAuditoria`: Se actualiza con los anexos de la auditoría.
     * - `abrirDialog`: Se invoca para abrir el diálogo de detalle con los datos de la auditoría.
     *
     * Manejo de Errores:
     * Si se produce un error en la solicitud, se registra en la consola y se llama a `showError` para mostrar
     * un mensaje de error al usuario.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getdetalleAuditoria(idAuditoria) {
      this.$http.get(`msa-localapp/api/v1/auditoria-concurrente/detalle-auditoria`, {
        params: { idAuditoria }
      })
      .then((result) => {
        console.log(result.data);
        this.abrirDialog(result.data);
        this.observaciones = result.data.observaciones || [];
        this.anexosAuditoria = result.data.anexosAuditoria;
      })
      .catch((error) => {
        console.error('Error al cargar las observaciones:', error);
        this.showError("Error al cargar las observaciones");
      });
    },

    /**
     * Método para abrir el diálogo de observaciones de una auditoría.
     *
     * Descripción:
     * Este método configura y abre el cuadro de diálogo que muestra la información detallada
     * de una auditoría, incluyendo su información general y el historial de seguimiento. 
     * Los datos recibidos se asignan a propiedades del componente para su visualización en la interfaz.
     *
     * Parámetros:
     * - `observacionesData` (Object): Objeto que contiene los datos de la auditoría, incluidos 
     *   `informacionAuditoria` (información general de la auditoría) y `segumientoAuditoria` 
     *   (historial de seguimiento).
     *
     * Ejemplo de uso:
     * this.abrirDialog(observacionesData);
     *
     * Efectos:
     * - `informacionAuditoria`: Se actualiza con la información general de la auditoría.
     * - `segumientoAuditoria`: Se actualiza con el historial de seguimiento de la auditoría.
     * - `dialogVerObservaciones`: Se establece en `true` para mostrar el diálogo de observaciones.
     *
     * @returns {void} No devuelve ningún valor.
     */
    abrirDialog(observacionesData) {
      this.informacionAuditoria = observacionesData.informacionAuditoria || {};
      this.segumientoAuditoria = observacionesData.segumientoAuditoria || [];
      this.dialogVerObservaciones = true;
    },

    /**
   * Método para mostrar el nombre de la ruta en la lista.
   *
   * Descripción:
   * Este método recibe un objeto `item` que representa una ruta y devuelve su
   * propiedad `nombreRuta`, que es el nombre de la ruta. Utiliza el operador de encadenamiento
   * opcional (`?.`) para evitar errores en caso de que el objeto `item` sea nulo o indefinido.
   *
   * Parámetros:
   * - `item` (Object): Objeto que representa una ruta, debe contener la propiedad `nombreRuta`.
   *
   * Ejemplo de uso:
   * this.mostrarRutas(item);
   *
   * Retorno:
   * - {String|undefined} El nombre de la ruta (`nombreRuta`) si existe, o `undefined` si el objeto `item` es nulo o no contiene la propiedad.
   */
    mostrarRutas(item) {
      return item?.nombreRuta
    },

    /**
     * Método para mostrar la descripción del tipo de documento en la lista.
     *
     * Descripción:
     * Este método recibe un objeto `item` que representa un tipo de documento y devuelve su
     * propiedad `descripcion`, que es la descripción del tipo de documento. Utiliza el operador de
     * encadenamiento opcional (`?.`) para evitar errores en caso de que el objeto `item` sea nulo o indefinido.
     *
     * Parámetros:
     * - `item` (Object): Objeto que representa un tipo de documento, debe contener la propiedad `descripcion`.
     *
     * Ejemplo de uso:
     * this.mostrarListaTipoDocumentos(item);
     *
     * Retorno:
     * - {String|undefined} La descripción del tipo de documento (`descripcion`) si existe, o `undefined` si el objeto `item` es nulo o no contiene la propiedad.
     */
    mostrarListaTipoDocumentos(item) {
      return item?.descripcion
    },

    /**
     * Método para obtener los motivos de inoportunidad de una auditoría desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar los motivos de inoportunidad relacionados
     * con una auditoría concurrente. Los datos obtenidos se asignan a la propiedad `opcionesInoportunidadRescate`
     * del componente para su uso en la interfaz de usuario. Durante el proceso de carga, la propiedad `cargandoMotivos`
     * se establece en `true` para indicar que se está realizando una solicitud. Al finalizar la carga, se establece en `false`.
     *
     * Ejemplo de uso:
     * this.getMotivosInoportunidad();
     *
     * Efectos:
     * - `opcionesInoportunidadRescate`: Se actualiza con los motivos de inoportunidad obtenidos desde el servidor.
     * - `cargandoMotivos`: Se establece en `true` cuando la solicitud está en curso y en `false` cuando finaliza.
     *
     * Manejo de Errores:
     * Si ocurre un error durante la solicitud, el error se muestra en la consola del navegador.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getMotivosInoportunidad() {
      this.cargandoMotivos = true;
      this.$http.get("msa-localapp/api/v1/auditoria-concurrente/motivos-inoportunidad")
        .then((response) => {
          this.opcionesInoportunidadRescate = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar los motivos de inoportunidad:", error);
        })
        .finally(() => {
          this.cargandoMotivos = false;  
        });
    },

    /**
     * Método para obtener los motivos de hospitalización de una auditoría desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar los motivos de hospitalización
     * relacionados con una auditoría concurrente. Los datos obtenidos se asignan a la propiedad 
     * `formCerrarAuditoria.opcionesMotivoHospitalizacion` del componente para su uso en la interfaz de usuario.
     * Durante el proceso de carga, la propiedad `cargandoMotivos` se establece en `true` para indicar que se está
     * realizando una solicitud. Al finalizar la carga, se establece en `false`.
     *
     * Ejemplo de uso:
     * this.getMotivosHospitalizacion();
     *
     * Efectos:
     * - `formCerrarAuditoria.opcionesMotivoHospitalizacion`: Se actualiza con los motivos de hospitalización obtenidos desde el servidor.
     * - `cargandoMotivos`: Se establece en `true` cuando la solicitud está en curso y en `false` cuando finaliza.
     *
     * Manejo de Errores:
     * Si ocurre un error durante la solicitud, el error se muestra en la consola del navegador.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getMotivosHospitalizacion() {
      this.cargandoMotivos = true; 
      this.$http.get("msa-localapp/api/v1/auditoria-concurrente/motivos-hospitalizacion")
        .then((response) => {
          this.formCerrarAuditoria.opcionesMotivoHospitalizacion = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar los motivos de hospitalizacion:", error);
        })
        .finally(() => {
          this.cargandoMotivos = false; 
        });
    },

    /**
     * Método para obtener los motivos de Unidad de Terapia (UT) de una auditoría desde el servidor.
     *
     * Descripción:
     * Este método realiza una solicitud GET a la API para recuperar los motivos de UT relacionados
     * con una auditoría concurrente. Los datos obtenidos se asignan a la propiedad 
     * `formCerrarAuditoria.opcionesMotivoUt` del componente para su uso en la interfaz de usuario.
     * Durante el proceso de carga, la propiedad `cargandoMotivos` se establece en `true` para indicar que se está
     * realizando una solicitud. Al finalizar la carga, se establece en `false`.
     *
     * Ejemplo de uso:
     * this.getMotivosUt();
     *
     * Efectos:
     * - `formCerrarAuditoria.opcionesMotivoUt`: Se actualiza con los motivos de UT obtenidos desde el servidor.
     * - `cargandoMotivos`: Se establece en `true` cuando la solicitud está en curso y en `false` cuando finaliza.
     *
     * Manejo de Errores:
     * Si ocurre un error durante la solicitud, el error se muestra en la consola del navegador.
     *
     * @returns {void} No devuelve ningún valor.
     */
    getMotivosUt() {
      this.cargandoMotivos = true; 
      this.$http.get("msa-localapp/api/v1/auditoria-concurrente/motivos-UT")
        .then((response) => {
          this.formCerrarAuditoria.opcionesMotivoUt = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar los motivos de hospitalizacion:", error);
        })
        .finally(() => {
          this.cargandoMotivos = false; 
        });
    },

    /**
     * Método para llenar los campos de información del afiliado basado en su número de documento.
     *
     * Descripción:
     * Este método realiza una validación del afiliado utilizando su número de documento a través de la 
     * función `validarAfiliado`. Si el afiliado es válido, llena los campos del formulario `formNuevaAuditoria`
     * con la información obtenida, como el nombre, apellido, género, municipio, entre otros. Además, se deshabilitan 
     * los campos de edición del afiliado si los datos son correctos. Si el afiliado no pertenece a la cohorte,
     * se muestra un mensaje de error y se limpia el formulario.
     *
     * El método usa `await` para esperar la respuesta de la validación antes de proceder con el llenado de campos.
     * Además, incluye un control con la variable `evitarLlenarCampos` para evitar el llenado de campos en ciertas circunstancias.
     *
     * Ejemplo de uso:
     * this.llenarCamposAfiliado();
     *
     * Efectos:
     * - Si la validación del afiliado es exitosa, los campos de `formNuevaAuditoria` se llenan con la información 
     *   del afiliado y se deshabilitan los campos de edición.
     * - Si el afiliado no pertenece a la cohorte, se limpia el formulario y se muestra un mensaje de error.
     * 
     * @returns {Promise<void>} No devuelve ningún valor. Es una función asíncrona que espera la respuesta de la validación del afiliado.
     */
    async llenarCamposAfiliado() { 
      if (this.evitarLlenarCampos) {
        this.evitarLlenarCampos = false;
        return;
      }
      await this.validarAfiliado(this.formNuevaAuditoria.numeroDocumento)
      if (this.respuestaValidarAfiliado != null) {
        this.habilitarCamposAfiliados = false;
        this.formNuevaAuditoria.idAfiliado = this.respuestaValidarAfiliado?.idAfiliado;
        this.formNuevaAuditoria.primerNombre = this.respuestaValidarAfiliado?.primerNombre;
        this.formNuevaAuditoria.segundoNombre = this.respuestaValidarAfiliado?.segundoNombre;
        this.formNuevaAuditoria.primerApellido = this.respuestaValidarAfiliado?.primerApellido;
        this.formNuevaAuditoria.segundoApellido = this.respuestaValidarAfiliado?.segundoApellido;
        this.formNuevaAuditoria.genero = this.respuestaValidarAfiliado?.genero;
        this.formNuevaAuditoria.municipio =  this.respuestaValidarAfiliado?.ciudad;
        this.formNuevaAuditoria.pacienteCohorte = this.respuestaValidarAfiliado?.cohorte;
      } else {
        this.habilitarCamposAfiliados = false;
        this.formNuevaAuditoria.idAfiliado = "";
        this.formNuevaAuditoria.primerNombre = "";
        this.formNuevaAuditoria.segundoNombre = "";
        this.formNuevaAuditoria.primerApellido = "";
        this.formNuevaAuditoria.segundoApellido = "";
        this.formNuevaAuditoria.genero = "";
        this.formNuevaAuditoria.numeroTelefono = "";
        this.formNuevaAuditoria.municipio = "";
        this.formNuevaAuditoria.pacienteCohorte = false;
        this.showError("El afiliado no pertenece a la cohorte");
        this.formNuevaAuditoria.numeroDocumento = "";
      }
    },

    /**
     * Método para calcular la edad de una persona a partir de su fecha de nacimiento.
     *
     * Descripción:
     * Este método calcula la edad de una persona tomando como referencia su fecha de nacimiento. Utiliza el objeto `Date`
     * de JavaScript para obtener la diferencia entre la fecha de hoy y la fecha de nacimiento en años. Luego, ajusta la edad
     * si el cumpleaños de la persona aún no ha ocurrido en el año actual.
     *
     * La edad se calcula teniendo en cuenta la diferencia de años y, si el cumpleaños no ha ocurrido este año, se resta un año a la edad.
     *
     * Ejemplo de uso:
     * const edad = this.calcularEdad("1990-05-15");
     *
     * Efectos:
     * - Retorna la edad de la persona basada en su fecha de nacimiento.
     *
     * @param {string} fechaNacimiento - La fecha de nacimiento de la persona en formato de fecha ISO (YYYY-MM-DD).
     * @returns {number} La edad calculada de la persona.
     */
    calcularEdad(fechaNacimiento) {
      // Convertir la fecha de nacimiento a un objeto Date
      const nacimiento = new Date(fechaNacimiento);
      const hoy = new Date();
      // Calcular la diferencia de años
      let edad = hoy.getFullYear() - nacimiento.getFullYear();
      // Ajustar la edad si el cumpleaños de este año aún no ha ocurrido
      const mesDiferencia = hoy.getMonth() - nacimiento.getMonth();
      const diaDiferencia = hoy.getDate() - nacimiento.getDate();
      if (mesDiferencia < 0 || (mesDiferencia === 0 && diaDiferencia < 0)) {
        edad--;
      }
      return edad;
    },
    
    /**
     * Método para llenar los campos de información de la cohorte en el formulario.
     *
     * Descripción:
     * Este método valida la información de la cohorte del afiliado utilizando su número de documento. Si la validación
     * es exitosa, llena los campos del formulario `formNuevaAuditoria` con los datos obtenidos, como la fecha de ingreso
     * a la cohorte, la edad calculada a partir de la fecha de nacimiento, el diagnóstico secundario y la IPS asignada.
     * Además, muestra el diagnóstico base cohorte concatenado en un campo específico.
     * 
     * Si la validación falla, limpia los campos correspondientes en el formulario.
     *
     * Ejemplo de uso:
     * this.llenarCamposInformacionCohorte();
     *
     * Efectos:
     * - Si la validación es exitosa, los campos del formulario `formNuevaAuditoria` se llenan con la información de la cohorte.
     * - Si la validación falla, se limpian los campos relacionados con la cohorte.
     *
     * @returns {Promise<void>} No devuelve ningún valor. Es una función asíncrona que espera la respuesta de la validación de la información de la cohorte.
     */
    async llenarCamposInformacionCohorte(){
      if (this.evitarLlenarCampos) {
        this.evitarLlenarCampos = false;
        return;
      }
      await this.validarInformacionCohorte(this.formNuevaAuditoria.numeroDocumento);
      if (this.respuestaValidarInformacionCohorte != null) {
        this.formNuevaAuditoria.fechaIngresoCohorte = this.respuestaValidarInformacionCohorte.fechaIngreso
        this.formNuevaAuditoria.edad = this.calcularEdad(this.respuestaValidarInformacionCohorte.fechaNacimiento) ?? "";
        // Guarda el código y el diagnóstico por separado
        this.formNuevaAuditoria.diagnosticoSecundario = this.respuestaValidarInformacionCohorte.codigoDiagnostico;
        this.formNuevaAuditoria.descripcionDiagnosticoSecundario = this.respuestaValidarInformacionCohorte.diagnostico;
        // Muestra el par concatenado en el campo Diagnostico Base Cohorte con model diagnosticoBaseCohorte
        // al objetoFinal a enviar va solo el id diagnostico: this.formNuevaAuditoria.diagnosticoSecundario
        this.formNuevaAuditoria.diagnosticoBaseCohorte = 
        `${this.formNuevaAuditoria.diagnosticoSecundario} - ${this.formNuevaAuditoria.descripcionDiagnosticoSecundario}` ?? "";
        this.formNuevaAuditoria.ipsAsignada = this.respuestaValidarInformacionCohorte.ipsAsignada;
      } else {
        this.formNuevaAuditoria.edad = "";
        this.formNuevaAuditoria.diagnosticoSecundario = "";
        this.formNuevaAuditoria.ipsAsignada = "";
      }
    },

    /**
     * Método para validar la existencia de un afiliado basado en el tipo y número de documento.
     *
     * Descripción:
     * Este método verifica si un afiliado existe en el sistema según el tipo de documento y el número de documento proporcionados.
     * Realiza una solicitud HTTP a la API para obtener los detalles del afiliado. Si la respuesta es exitosa, almacena los datos del afiliado
     * en `respuestaValidarAfiliado` y resuelve la promesa con `true`. Si hay un error o no se encuentra el afiliado, resuelve la promesa con `false`.
     *
     * Ejemplo de uso:
     * this.validarAfiliado('123456789').then((existe) => {
     *   if (existe) {
     *     console.log('Afiliado encontrado');
     *   } else {
     *     console.log('Afiliado no encontrado');
     *   }
     * });
     *
     * Efectos:
     * - Si el afiliado existe, almacena la información del afiliado en `respuestaValidarAfiliado`.
     * - Si no se encuentra al afiliado o hay un error en la consulta, se resuelve con `false`.
     *
     * @param {string} item - El número de documento del afiliado a validar.
     * @returns {Promise<boolean>} Retorna una promesa que se resuelve con `true` si el afiliado existe, o `false` en caso contrario.
     */
    validarAfiliado(item) {
      if(this.formNuevaAuditoria.tipoDocumento.tipoDocumento != '' && item != '' && this.formNuevaAuditoria.tipoDocumento != null && item != null){
        let tipoDocumento;
        if(this.formNuevaAuditoria.tipoDocumento.tipoDocumento != null){
          tipoDocumento = this.formNuevaAuditoria.tipoDocumento.tipoDocumento;
        }else{
          tipoDocumento = this.formNuevaAuditoria.tipoDocumento;
        }
        return new Promise((resolve) => {
          let numeroDocumento = item != '' ? item : 'null'
          this.$http.get("msa-localapp/api/v1/afiliado/" + tipoDocumento + '/' + numeroDocumento).then((result) => {
            this.respuestaValidarAfiliado = result.data
            console.log(this.respuestaValidarAfiliado)
            resolve(true)
          }).catch(() => {
            resolve(false)
          })
        })
      }
    },
    
    /**
     * Método para validar la existencia de información de cohorte para un afiliado basado en el tipo y número de documento.
     *
     * Descripción:
     * Este método verifica si un afiliado tiene información de cohorte asociada en el sistema, utilizando el tipo de documento
     * y el número de documento proporcionados. Realiza una solicitud HTTP a la API para obtener los detalles de la cohorte. 
     * Si la respuesta es exitosa, almacena los datos de la cohorte en `respuestaValidarInformacionCohorte` y resuelve la promesa con `true`.
     * Si la validación falla o no se encuentra la información, resuelve la promesa con `false`.
     *
     * Ejemplo de uso:
     * this.validarInformacionCohorte('123456789').then((existe) => {
     *   if (existe) {
     *     console.log('Información de cohorte encontrada');
     *   } else {
     *     console.log('Información de cohorte no encontrada');
     *   }
     * });
     *
     * Efectos:
     * - Si la validación es exitosa, los datos de la cohorte se almacenan en `respuestaValidarInformacionCohorte`.
     * - Si no se encuentra información de la cohorte o hay un error en la consulta, se resuelve con `false`.
     *
     * @param {string} item - El número de documento del afiliado para verificar la existencia de información de cohorte.
     * @returns {Promise<boolean>} Retorna una promesa que se resuelve con `true` si la información de cohorte existe, o `false` en caso contrario.
     */
    validarInformacionCohorte(item){
      let tipoDocumento;
      if(this.formNuevaAuditoria.tipoDocumento.tipoDocumento != null){
        tipoDocumento = this.formNuevaAuditoria.tipoDocumento.tipoDocumento;
      }else{
        tipoDocumento = this.formNuevaAuditoria.tipoDocumento;
      }
      return new Promise((resolve) => {
        let numeroDocumento = item != '' ? item : 'null'
        this.$http.get("msa-localapp/api/v1/auditoria-concurrente/informacion-cohorte", 
        { params: { tipoDocumento: tipoDocumento, numeroDocumento: numeroDocumento }
        }).then((result) => {
          this.respuestaValidarInformacionCohorte = result.data
          console.log(this.respuestaValidarInformacionCohorte)
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    },

    /**
     * Método para mostrar una notificación tipo snackbar.
     *
     * Descripción:
     * Este método utiliza la funcionalidad de notificación global de Vue (por medio de un plugin de notificación) para mostrar
     * un mensaje de notificación en la interfaz de usuario. La notificación puede ser de diferentes tipos (como 'success', 'error', etc.),
     * y el mensaje que se muestra es el proporcionado como argumento.
     *
     * Ejemplo de uso:
     * this.mostrarSnackbarNotificacion('success', 'Operación completada con éxito');
     * this.mostrarSnackbarNotificacion('error', 'Ha ocurrido un error');
     *
     * Efectos:
     * - Muestra una notificación tipo snackbar en la pantalla con el mensaje proporcionado.
     * - El tipo de notificación puede ser definido por el parámetro `tipo`, que puede ser 'success', 'error', o cualquier otro tipo
     *   soportado por el sistema de notificación.
     *
     * @param {string} tipo - El tipo de notificación a mostrar (por ejemplo, 'success', 'error', 'info', etc.).
     * @param {string} mensaje - El mensaje que se mostrará en la notificación.
     */
    mostrarSnackbarNotificacion(tipo, mensaje) {
      this.$notify({
        group: 'notificaciones',
        type: tipo, // success, error, etc.
        text: mensaje,
      });
    },

    /**
    * Guarda la fecha seleccionada en el menú de selección de período de carga.
    * 
    * Este método utiliza una referencia (`ref`) llamada `menuPeriodoCarga` para acceder a un componente
    * específico y llama a su método `save` con la fecha proporcionada como argumento.
    * 
    * @param {Date} date - La fecha seleccionada que se va a guardar.
    */
    savePeriodoCargaVisible(date) {
      this.$refs.menuPeriodoCarga.save(date);
      if (date) {
        const fechaSeleccionada = new Date(date);
        const mes = fechaSeleccionada.toLocaleString('es-CO', { month: 'long' }); // Extrae solo el mes
        this.formNuevaAuditoria.mesIngreso = mes; // Asigna solo el nombre del mes al campo mesIngreso
      }
    },

    /**
     * Método para guardar la fecha de egreso seleccionada y asignar el mes correspondiente al campo `mesEgreso`.
     *
     * Descripción:
     * Este método se encarga de guardar la fecha seleccionada en un componente de menú desplegable, utilizando la referencia 
     * de `menuPeriodoCargaFechaEgresoIps`, y luego extraer el mes de la fecha para asignarlo al campo `mesEgreso` del formulario.
     * El mes se extrae como un número del 1 al 12 (donde enero es 1, febrero es 2, etc.), y se almacena en el objeto `formCerrarAuditoria`.
     *
     * Ejemplo de uso:
     * this.savePeriodoCargaVisibleFechaEgresoIps('2024-11-08'); // Guarda la fecha y asigna el mes de noviembre
     *
     * Efectos:
     * - Guarda la fecha proporcionada en el componente referenciado `menuPeriodoCargaFechaEgresoIps`.
     * - Si se proporciona una fecha válida, extrae el mes y lo asigna al campo `mesEgreso` del objeto `formCerrarAuditoria`.
     *
     * @param {string} date - La fecha de egreso seleccionada (en formato 'YYYY-MM-DD').
     */
    savePeriodoCargaVisibleFechaEgresoIps(date) {
      this.$refs.menuPeriodoCargaFechaEgresoIps.save(date);
      if (date) {
        const fechaSeleccionada = new Date(date);
        const mesNumero = fechaSeleccionada.getMonth() + 1; // Devuelve el mes en formato numérico (1-12)
        this.formCerrarAuditoria.mesEgreso = mesNumero; // Asigna el número del mes al campo mesEgreso
      }
    },

    /**
     * Método para guardar la fecha de la cita pre-hospitalaria seleccionada.
     *
     * Descripción:
     * Este método se encarga de guardar la fecha proporcionada en un componente de menú desplegable, 
     * utilizando la referencia de `menuPeriodoCargaFechaCitaPreHospitalaria`. No realiza ningún procesamiento adicional 
     * sobre la fecha, solo la guarda en el componente correspondiente.
     *
     * Ejemplo de uso:
     * this.savePeriodoCargaVisibleFechaCitaPreHospitalaria('2024-11-08'); // Guarda la fecha de la cita pre-hospitalaria
     *
     * Efectos:
     * - Guarda la fecha proporcionada en el componente de menú referenciado `menuPeriodoCargaFechaCitaPreHospitalaria`.
     *
     * @param {string} date - La fecha de la cita pre-hospitalaria seleccionada (en formato 'YYYY-MM-DD').
     */
    savePeriodoCargaVisibleFechaCitaPreHospitalaria(date) {
      this.$refs.menuPeriodoCargaFechaCitaPreHospitalaria.save(date);
    },

    /**
     * Método para obtener la lista de municipios de Colombia desde el API.
     *
     * Descripción:
     * Este método realiza una solicitud HTTP GET al endpoint `msa-localapp/api/GetListaMpiosColombia` 
     * para obtener la lista de municipios en Colombia. Los datos obtenidos se almacenan en la propiedad `listaMunicipios`.
     * 
     * Ejemplo de uso:
     * this.getListaMunicipios(); // Obtiene la lista de municipios de Colombia
     *
     * Efectos:
     * - Realiza una solicitud HTTP GET para obtener la lista de municipios de Colombia.
     * - Almacena la respuesta en la propiedad `listaMunicipios`.
     *
     * @returns {void}
     */
    getListaMunicipios() {
      this.$http.get("msa-localapp/api/GetListaMpiosColombia").then((result) => {
        this.listaMunicipios = result.data
      })
    },

    /**
     * Método para mostrar el nombre de la ciudad de un municipio.
     *
     * Descripción:
     * Este método recibe un objeto que representa un municipio y devuelve el nombre de la ciudad asociada a ese municipio.
     * Si el objeto proporcionado tiene la propiedad `ciudad`, esta se retorna. Si no, el método no devuelve nada.
     * 
     * Ejemplo de uso:
     * this.mostrarMunicipios(municipio); // Retorna el nombre de la ciudad del municipio
     *
     * @param {Object} item - El objeto que representa el municipio, debe contener la propiedad `ciudad`.
     * @returns {string | undefined} - El nombre de la ciudad si existe, de lo contrario `undefined`.
     */
    mostrarMunicipios(item) {
      return item?.ciudad
    },

    /**
     * Método para ejecutar `getListaGrupoDiagnostico` con un retraso (debounced) de 500 milisegundos.
     *
     * Descripción:
     * Este método aplica un retardo de 500 milisegundos a la ejecución de la función `getListaGrupoDiagnostico`. 
     * Esto es útil para optimizar llamadas a la API al evitar múltiples solicitudes consecutivas en un corto periodo de tiempo 
     * (por ejemplo, al escribir en un campo de búsqueda).
     *
     * Ejemplo de uso:
     * this.delayGrupoDiagnostico("diagnostico"); // Llama a `getListaGrupoDiagnostico` después de 500 ms
     *
     * @param {string} param - El diagnóstico que se usará como parámetro para obtener los grupos de diagnóstico.
     * @returns {void}
     */
    delayGrupoDiagnostico: debounce(function(param) {
      this.getListaGrupoDiagnostico(param)
    }, 500),

    /**
     * Método para obtener la lista de grupos de diagnóstico desde la API.
     *
     * Descripción:
     * Este método realiza una solicitud HTTP GET al endpoint `msa-localapp/api/GetListaGruposDiagnostico`, 
     * pasando el diagnóstico como parámetro en la solicitud. Los datos obtenidos se almacenan en la propiedad `listaGrupoDiagnostico`.
     * 
     * Ejemplo de uso:
     * this.getListaGrupoDiagnostico("diagnostico"); // Obtiene la lista de grupos de diagnóstico para el diagnóstico dado
     *
     * Efectos:
     * - Realiza una solicitud HTTP GET para obtener la lista de grupos de diagnóstico.
     * - Almacena la respuesta en la propiedad `listaGrupoDiagnostico`.
     *
     * @param {string} param - El diagnóstico que se usará como parámetro para obtener los grupos de diagnóstico.
     * @returns {void}
     */
    getListaGrupoDiagnostico(param) {
      this.$http.get("msa-localapp/api/GetListaGruposDiagnostico", {
        params:{
          diagnostico: param
        }
      }).then((result) => {
        this.listaGrupoDiagnostico = result.data
      })
    },

    /**
     * Método para emitir el evento `delayGrupoDiagnostico` con el parámetro proporcionado.
     *
     * Descripción:
     * Este método recibe un parámetro (`param`) y emite un evento llamado `delayGrupoDiagnostico` con ese parámetro. 
     * El propósito de este evento es invocar la función `delayGrupoDiagnostico`, que está "debounced", 
     * para evitar múltiples solicitudes rápidas cuando se realiza una búsqueda o actualización en el sistema.
     *
     * Ejemplo de uso:
     * this.buscarGrupoCie("diagnostico"); // Emite el evento `delayGrupoDiagnostico` con el parámetro "diagnostico"
     *
     * @param {string} param - El diagnóstico o término de búsqueda que se utilizará para obtener los grupos de diagnóstico.
     * @returns {void}
     */
    buscarGrupoCie(param){
      this.$emit('delayGrupoDiagnostico', param);
    },

    /**
     * Método para mostrar una representación concatenada del grupo diagnóstico.
     *
     * Descripción:
     * Este método recibe un objeto `item` y devuelve una cadena de texto que representa el grupo diagnóstico 
     * en un formato combinado: `igrupo_cie - idescripcion_grupo`. 
     * Se utiliza para mostrar una representación legible de los grupos de diagnóstico en la interfaz de usuario.
     *
     * Ejemplo de uso:
     * this.mostrarGrupoDiagnostico(grupo); // Retorna una cadena con el formato "igrupo_cie - idescripcion_grupo"
     *
     * @param {Object} item - El objeto que contiene los datos del grupo diagnóstico.
     * @param {string} item.igrupo_cie - El código del grupo de diagnóstico.
     * @param {string} item.idescripcion_grupo - La descripción del grupo de diagnóstico.
     * @returns {string} - Una cadena de texto que concatena el código y la descripción del grupo de diagnóstico.
     */
    mostrarGrupoDiagnostico(item) {
      return item?.igrupo_cie + ' - ' + item?.idescripcion_grupo;
    },

    /**
     * Método para modificar y estructurar el objeto final a partir de los valores del formulario de auditoría.
     *
     * Descripción:
     * Este método recopila los valores del formulario `formNuevaAuditoria`, realiza algunas transformaciones y asignaciones,
     * y luego estructura esos datos en un objeto `objetoFinal`. Este objeto contiene los datos relevantes para el proceso 
     * de auditoría y es retornado al final del método. Se utilizan operaciones como el formateo de fechas con `moment` 
     * y la búsqueda de índices en arrays para completar las asignaciones.
     *
     * Ejemplo de uso:
     * let objetoModificado = this.modificarArray(); // Retorna el objetoFinal con los datos modificados.
     *
     * @returns {Object} - El objeto `objetoFinal` con los datos transformados y organizados.
     */
    modificarArray() {
      this.objetoFinal.tipoIngreso = this.opcionesTipoIngreso.indexOf(this.formNuevaAuditoria.tipoIngreso);
      this.objetoFinal.cohorte = this.formNuevaAuditoria.pacienteCohorte;              
      this.objetoFinal.fechaIngresoIps = moment(this.formNuevaAuditoria.fechaCarga).format('YYYY-MM-DD');          
      this.objetoFinal.ipsOrigen = this.formNuevaAuditoria.ips.id_prestador;
      this.objetoFinal.riesgoCardiovascular = this.formNuevaAuditoria.riesgoCardiovascular;      
      this.objetoFinal.ipsRescate = this.formNuevaAuditoria.ipsRescate;                
      this.objetoFinal.inoportunidadRescate = this.formNuevaAuditoria.inoportunidadRescate;      
      this.objetoFinal.idMotivoInoportunidad = this.formNuevaAuditoria.idMotivoInoportunidad;
      this.objetoFinal.reingreso = this.formNuevaAuditoria.reingreso;                 
      this.objetoFinal.idAfiliado = this.formNuevaAuditoria.idAfiliado;                
      this.objetoFinal.idDiagnostico = this.formNuevaAuditoria.diagnosticoHospitalizacion.igrupo_cie;             
      this.objetoFinal.diagnosticoCardiovascular = this.formNuevaAuditoria.diagnosticoRiesgo; 
      this.objetoFinal.idRuta = this.formNuevaAuditoria.riaAtencion.idRuta;
      
      this.objetoFinal.idDiagnosticoSecundario = this.formNuevaAuditoria.diagnosticoSecundario;
      this.objetoFinal.ipsAsignada = this.formNuevaAuditoria.ipsAsignada;

      console.log(this.objetoFinal)
      return this.objetoFinal
    },

    /**
     * Método para validar y guardar una nueva auditoría.
     *
     * Descripción:
     * Este método realiza varias validaciones sobre los campos del formulario `formNuevaAuditoria` para asegurar que no haya datos 
     * faltantes o incorrectos. Si alguna de las validaciones falla, se muestra un mensaje de error utilizando el método `showError`. 
     * Si todos los campos son válidos, se recopilan los datos del formulario, se modifican y estructuran en el objeto `objetoFinal` 
     * mediante el método `modificarArray`. Luego, se envía una solicitud HTTP `POST` para guardar la auditoría en el servidor. 
     * Si la auditoría se guarda correctamente, se muestra un mensaje de éxito y se limpia el formulario. 
     * Si ocurre un error en el proceso, se muestra un mensaje de error.
     *
     * Ejemplo de uso:
     * await this.guardarNuevaAuditoria(); // Guardará la auditoría después de validar los datos.
     *
     * @returns {Promise<void>} - No retorna nada, pero realiza operaciones asincrónicas para guardar la auditoría.
     */
    async guardarNuevaAuditoria() { console.log(this.formNuevaAuditoria)
      // Validación de campos vacíos
      if (!this.formNuevaAuditoria.ips || !this.formNuevaAuditoria.ips.id_prestador) {
        this.showError("El campo 'IPS Hospitalización' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.riaAtencion || !this.formNuevaAuditoria.riaAtencion.idRuta) {
        this.showError("El campo 'Ruta de Atención' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.fechaCarga) {
        this.showError("El campo 'Fecha de Ingreso a la IPS' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.idAfiliado) {
        this.showError("El campo 'ID del Afiliado' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.pacienteCohorte) {
        this.showError("El campo 'Cohorte del Paciente' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.ipsAsignada) {
        this.showError("El campo 'IPS Asignada' es obligatorio."); return;
      }
      if (!this.formNuevaAuditoria.diagnosticoHospitalizacion || !this.formNuevaAuditoria.diagnosticoHospitalizacion.igrupo_cie) {
        this.showError("El campo 'Diagnóstico de Hospitalización' es obligatorio."); return;
      }
      if (this.formNuevaAuditoria.riesgoCardiovascular === '') {
        this.showError("El campo 'Riesgo Cardiovascular' es obligatorio.");
        return;
      }
      if (this.formNuevaAuditoria.riesgoCardiovascular === true && !this.formNuevaAuditoria.diagnosticoRiesgo) {
        this.showError("El campo 'Diagnóstico relacionado a riesgo cardiovascular' es obligatorio cuando el Riesgo Cardiovascular es 'Sí'."); return;
      }
      if (!this.formNuevaAuditoria.diagnosticoSecundario) {
        this.showError("El campo 'Diagnóstico Base Cohorte' es obligatorio."); return;
      }
      if (this.formNuevaAuditoria.tipoIngreso === '') {
        this.showError("El campo 'Tipo de Ingreso' es obligatorio."); return; 
      }
      if (this.formNuevaAuditoria.tipoIngreso != 'Espontáneo' && !this.formNuevaAuditoria.ipsRescate) {
        this.showError("El campo 'IPS Rescate' es obligatorio cuando el tipo de ingreso es Remisión o Rescate."); return;
      }
      if (this.formNuevaAuditoria.inoportunidadRescate === '') {
        this.showError("El campo 'Inoportunidad de Rescate' es obligatorio."); return;
      }
      if (this.formNuevaAuditoria.inoportunidadRescate === true && !this.formNuevaAuditoria.idMotivoInoportunidad) {
        this.showError("El campo 'Motivo de Inoportunidad' es obligatorio cuando Inoportunidad rescate es 'Sí'."); return;
      }
      if (this.formNuevaAuditoria.reingreso === '') {
        this.showError("El campo 'Reingreso' es obligatorio."); return;
      } 
      

      this.modificarArray(); console.log(this.objetoFinal)
      // if (this.formNuevaAuditoria.length == this.objetoFinal.cuerpo.length) {
      let obj = JSON.stringify(this.objetoFinal);
      //this.formData.append('objetoFinal', new Blob([obj], { type: 'application/json' }));
      this.$http.post("msa-localapp/api/v1/auditoria-concurrente/crear-auditoria", obj).then(() => {
        this.showSuccess("Auditoria creada correctamente");
        this.limpiarFormulario();
        this.dialogCrearAuditoria = false;
        //this.actualizarDialogSolicitud()
        this.objetoFinal = [];
        this.formData = new FormData();
        this.getAuditorias();
      }).catch((error) => {
        console.log(error.response.data.message)
        this.showError("No fue posible guardar la auditoria")
      })
    },

    /**
    * Muestra un mensaje de éxito en un snackbar.
    * 
    * Este método configura y muestra un snackbar con un mensaje de éxito específico,
    * cambiando el color del snackbar a verde para indicar una operación exitosa.
    *
    * @param {string} message - El mensaje de éxito a mostrar en el snackbar.
    */
    showSuccess(message) {
      this.snackbarMessage = message;
      this.snackbarColor = 'success';
      this.snackbar = true;
    },

    /**
    * Muestra un mensaje de error en un snackbar.
    * 
    * Este método configura y muestra un snackbar con un mensaje de error específico,
    * cambiando el color del snackbar a rojo para indicar que ha ocurrido un error.
    *
    * @param {string} message - El mensaje de error a mostrar en el snackbar.
    */
    showError(message) {
      this.snackbarMessage = message;
      this.snackbarColor = 'error';
      this.snackbar = true;
    },

    /**
     * Método para limpiar todos los campos del formulario `formNuevaAuditoria`.
     *
     * Descripción:
     * Este método se encarga de restablecer todos los valores del formulario `formNuevaAuditoria` a sus valores predeterminados, 
     * es decir, vacía los campos de texto, restablece las selecciones a su estado inicial, y limpia cualquier campo relacionado 
     * con la auditoría que se esté creando. También restablece las banderas de control como `habilitarCamposAfiliados` y 
     * `evitarLlenarCampos`, asegurándose de que el formulario esté completamente limpio y listo para una nueva entrada de datos.
     *
     * Ejemplo de uso:
     * this.limpiarFormulario(); // Limpia todos los campos del formulario.
     *
     * @returns {void} - No retorna nada. Solo limpia los valores del formulario.
     */
    limpiarFormulario() {
      this.habilitarCamposAfiliados = false;
      this.evitarLlenarCampos = true; // Activamos la bandera

      this.formNuevaAuditoria.tipoDocumento = "";
      this.formNuevaAuditoria.idAfiliado = "";
      this.formNuevaAuditoria.primerNombre = "";
      this.formNuevaAuditoria.segundoNombre = "";
      this.formNuevaAuditoria.primerApellido = "";
      this.formNuevaAuditoria.segundoApellido = "";
      this.formNuevaAuditoria.genero = "";
      this.formNuevaAuditoria.numeroTelefono = "";
      this.formNuevaAuditoria.municipio = "";
      this.formNuevaAuditoria.pacienteCohorte = "";
      this.formNuevaAuditoria.numeroDocumento = "";

      this.formNuevaAuditoria.mesIngreso                  = "";
      this.formNuevaAuditoria.edad                        = "";
      this.formNuevaAuditoria.tipoIngreso                 = "";
      this.formNuevaAuditoria.fechaCarga                  = null; // Propiedad para el v-model del date picker
      this.formNuevaAuditoria.ips                         = "";
      this.formNuevaAuditoria.ipsAsignada                 = "";
      this.formNuevaAuditoria.riesgoCardiovascular        = ""; 
      this.formNuevaAuditoria.diagnosticoRiesgo           = ""; // no requerido
      this.formNuevaAuditoria.ipsRescate                  = ""; 
      this.formNuevaAuditoria.inoportunidadRescate        = "";
      this.formNuevaAuditoria.idMotivoInoportunidad       = null; // no requerido
      this.formNuevaAuditoria.reingreso                   = "";
      this.formNuevaAuditoria.idAfiliado                  = "";
      this.formNuevaAuditoria.diagnosticoHospitalizacion  = "";
      this.formNuevaAuditoria.diagnosticoSecundario       = "";
      this.formNuevaAuditoria.riaAtencion                 = "";
      this.formNuevaAuditoria.diagnosticoBaseCohorte      = "";
    },

    /**
     * Método para abrir el modal de creación de observación con la información del ítem seleccionado.
     *
     * Descripción:
     * Este método se encarga de preparar los datos necesarios y abrir un modal de creación de observación.
     * Al invocar este método, se asignan el número de documento, tipo de documento y el ID de auditoría concurrente del ítem 
     * pasado como argumento a las propiedades correspondientes del componente. Luego, establece la bandera `dialogCrearObservacion` 
     * como `true`, lo que activa el modal en la interfaz de usuario.
     *
     * Ejemplo de uso:
     * this.abrirModalCrearObservacion(item); // Abre el modal con los datos del ítem seleccionado.
     *
     * @param {Object} item - El ítem de auditoría que contiene la información para llenar el modal.
     * @param {string} item.numeroDocumento - El número de documento del afiliado.
     * @param {string} item.tipoDocumento - El tipo de documento del afiliado.
     * @param {string} item.idAuditoria - El ID de la auditoría concurrente asociada al ítem.
     *
     * @returns {void} - No retorna nada. Solo abre el modal de creación de observación.
     */
    abrirModalCrearObservacion(item) {
      this.numDoc = item.numeroDocumento;
      this.tipoDoc = item.tipoDocumento;
      this.idAuditoriaConcurrente = item.idAuditoria;
      this.dialogCrearObservacion = true;
    },

    /**
     * Método para guardar una nueva observación relacionada con una auditoría concurrente.
     *
     * Descripción:
     * Este método maneja la lógica para guardar una observación en el sistema. Recibe la observación, el ID de la auditoría concurrente, 
     * y opcionalmente un archivo de historia clínica. Los datos se envían al backend utilizando `FormData`, lo que permite enviar tanto el 
     * archivo como los datos JSON de forma conjunta.
     * 
     * El método realiza una petición `POST` al servidor, y en caso de éxito, muestra una notificación de éxito y recarga la página
     * para limpiar los campos de entrada. Si ocurre un error, muestra un mensaje de error.
     * 
     * Ejemplo de uso:
     * this.guardarObservacion(); // Guarda la observación con los datos proporcionados.
     * 
     * @returns {void} - No retorna ningún valor, pero maneja la carga de datos y muestra mensajes de éxito o error.
     */
    async guardarObservacion() {
      this.loading = true;
      // Crear un objeto FormData para enviar tanto el archivo como el DTO
      const formData = new FormData();
      // Añadir la observación e idAuditoriaConcurrente al DTO
      const seguimientoAuditoriaConcurrenteDto = {
        observacion: this.observacion,
        idAuditoriaConcurrente: this.idAuditoriaConcurrente // Asegúrate de asignar este valor desde el item seleccionado
      };
      // Añadir el archivo seleccionado si existe
      if (this.historiaClinica) {
        formData.append('historiaClinica', this.historiaClinica);
      }
      // Convertir el DTO en un Blob de tipo JSON y añadirlo a formData con el nombre 'objetoFinal'
      // que es el nombre del objeto que espera el controller en el back
      formData.append('objetoFinal', new Blob([JSON.stringify(seguimientoAuditoriaConcurrenteDto)], { type: 'application/json' }));
      // Enviar los datos al backend
      try {
        await this.$http.post('msa-localapp/api/v1/auditoria-concurrente/nueva-observacion', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // Manejar la respuesta exitosa
        this.showSuccess('Observación guardada correctamente');
        this.loading = false;
        this.dialogCrearObservacion = false;

        // Recargar la página después de 5 segundos para limpiar los inputs
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (error) {
        this.loading = false;

        // Manejar el error y mostrar el mensaje adecuado
        let errorMessage = 'Ocurrió un error al guardar la observación';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = Array.isArray(error.response.data.message) ? error.response.data.message.join(', ') : error.response.data.message;
        }
        this.showError(errorMessage);
      }

    },

    /**
     * Método para abrir el modal de visualización de observaciones de una auditoría.
     *
     * Descripción:
     * Este método se encarga de abrir un modal para visualizar las observaciones relacionadas con una auditoría específica. 
     * Cuando se invoca, realiza una llamada al backend para obtener los detalles de la auditoría (incluyendo las observaciones) 
     * usando el método `getdetalleAuditoria` y luego muestra el modal con la información correspondiente.
     * 
     * Ejemplo de uso:
     * this.abrirModalVerObservaciones(item); // Abre el modal para ver las observaciones de la auditoría indicada por 'item'.
     * 
     * @param {Object} item - Objeto que contiene los datos de la auditoría. Debe incluir al menos el campo 'idAuditoria'.
     * @returns {void} - No retorna ningún valor, pero abre el modal para mostrar las observaciones.
     */
    abrirModalVerObservaciones(item) {
      // Cargar observaciones desde el backend
      this.getdetalleAuditoria(item.idAuditoria);
      this.dialogVerObservaciones = true;
    },

    /**
     * Método para traducir el estado de una auditoría.
     *
     * Descripción:
     * Este método convierte los valores del estado de una auditoría en su equivalente en español. 
     * Utiliza un `switch` para comprobar el estado y retornar una cadena de texto con la traducción correspondiente.
     * Si el estado no está definido en el `switch`, se retorna el valor original.
     * 
     * Ejemplo de uso:
     * let estadoTraduccion = this.traducirEstadoAuditoria('CREATED'); // Retorna 'CREADA'
     * 
     * @param {string} estado - El estado de la auditoría que se desea traducir.
     * @returns {string} - El estado traducido al español o el valor original si no se encuentra en el `switch`.
     */
    traducirEstadoAuditoria(estado) {
      switch (estado) {
        case "CREATED":
          return "CREADA";
        case "REVIEW":
          return "EN REVISIÓN";
        case "CLOSED":
          return "CERRADA";
        default:
          return estado; // Si no se encuentra, mostrar el valor original
      }
    },

    /**
     * Método para actualizar las opciones disponibles para el destino del paciente 
     * en función de su condición de egreso (Vivo o Fallecido).
     *
     * Descripción:
     * Este método filtra las opciones de destino disponibles (`destinoAfiliadoOptions`) 
     * según la condición de egreso del paciente (`condicionEgreso`). Si el paciente está 
     * marcado como "Vivo" (condición `true`), solo se mostrarán las opciones correspondientes 
     * a pacientes vivos, como 'R', 'CR', y 'H'. Si el paciente está marcado como "Fallecido" 
     * (condición `false`), se mostrarán opciones para pacientes fallecidos, como 'LD', 'HD', y 'PD'. 
     * Si no hay ninguna condición seleccionada, se limpia la lista de opciones.
     *
     * Ejemplo de uso:
     * this.updateDestinoPacienteOptions();
     *
     * @returns {void}
     */
    updateDestinoPacienteOptions() {
      if (this.formCerrarAuditoria.condicionEgreso === true) {
        // Si la condición es "Vivo", muestra solo estas opciones
        this.filteredDestinoAfiliadoOptions = this.destinoAfiliadoOptions.filter(option => 
          ['R', 'CR', 'H'].includes(option.value)
        );
      } else if (this.formCerrarAuditoria.condicionEgreso === false) {
        // Si la condición es "Fallecido", muestra solo estas opciones
        this.filteredDestinoAfiliadoOptions = this.destinoAfiliadoOptions.filter(option => 
          ['LD', 'HD', 'PD'].includes(option.value)
        );
      } else {
        // Si no hay selección, limpia las opciones
        this.filteredDestinoAfiliadoOptions = [];
      }
      // Resetear el campo destinoPaciente
      this.formCerrarAuditoria.destinoPaciente = null;
    },

    /**
     * Método para abrir el modal de cierre de auditoría y cargar los datos del item seleccionado.
     *
     * Descripción:
     * Este método se ejecuta cuando se desea abrir el modal para cerrar una auditoría. 
     * Toma un objeto `item` (probablemente una auditoría previamente cargada) y extrae 
     * su `idAuditoria` para almacenarlo en la propiedad `idAuditoria`. Luego, se activa 
     * el modal de cierre de auditoría (`dialogCerrarAuditoria`) estableciendo su valor a `true`.
     *
     * Ejemplo de uso:
     * this.abrirModalCerrarAuditoria(item);
     *
     * @param {Object} item - El objeto que representa la auditoría que se va a cerrar. Debe contener al menos el campo `idAuditoria`.
     * @returns {void}
     */
    abrirModalCerrarAuditoria(item) {
      this.idAuditoria = item.idAuditoria;
      this.dialogCerrarAuditoria = true;
    },

    /**
     * Método para modificar y construir el objeto final con los datos del formulario de cierre de auditoría.
     *
     * Descripción:
     * Este método recopila los valores del formulario de cierre de auditoría (`formCerrarAuditoria`), 
     * los asigna a las propiedades correspondientes del objeto `objetoFinalCerrar` y devuelve dicho objeto.
     * Es utilizado para construir un objeto que contiene todos los datos necesarios para enviar al backend 
     * para cerrar una auditoría.
     *
     * Ejemplo de uso:
     * let objetoFinal = this.modificarArray2();
     *
     * @returns {Object} objetoFinalCerrar - El objeto que contiene los datos del formulario de cierre de auditoría.
     */
    modificarArray2() {
      this.objetoFinalCerrar.idAuditoria	            = this.idAuditoria;
      this.objetoFinalCerrar.tipoEstancia				      = this.formCerrarAuditoria.tipoEstancia;
      this.objetoFinalCerrar.fechaEgresoIps			      = this.formCerrarAuditoria.fechaEgresoIps;
      this.objetoFinalCerrar.diasHospitalizacion	    = this.formCerrarAuditoria.diasHospitalizacion;
      this.objetoFinalCerrar.mesEgreso				        = this.formCerrarAuditoria.mesEgreso;
      this.objetoFinalCerrar.diasInoportunidad		    = this.formCerrarAuditoria.diasInoportunidad;
      this.objetoFinalCerrar.motivoInoportunidad      = this.formCerrarAuditoria.motivoInoportunidad;
      this.objetoFinalCerrar.condicionEgreso			    = this.formCerrarAuditoria.condicionEgreso;
      this.objetoFinalCerrar.destinoPaciente			    = this.formCerrarAuditoria.destinoPaciente;
      this.objetoFinalCerrar.sitioRemision			      = this.formCerrarAuditoria.sitioRemision.nombre_prestador;
      this.objetoFinalCerrar.facturaPatologia			    = this.formCerrarAuditoria.facturaPatologia;
      this.objetoFinalCerrar.idMotivoHospitalizacion  = this.formCerrarAuditoria.idMotivoHospitalizacion;
      this.objetoFinalCerrar.idMotivoUT				        = this.formCerrarAuditoria.idMotivoUT;
      this.objetoFinalCerrar.valorCargoUt				      = this.formCerrarAuditoria.valorCargoUt;
      this.objetoFinalCerrar.eventoAdverso			      = this.formCerrarAuditoria.eventoAdverso;
      this.objetoFinalCerrar.infeccion				        = this.formCerrarAuditoria.infeccion;
      this.objetoFinalCerrar.tasaMortalidad			      = this.formCerrarAuditoria.tasaMortalidad;
      this.objetoFinalCerrar.polimedicado				      = this.formCerrarAuditoria.polimedicado;
      this.objetoFinalCerrar.conciliacionMedicamento	= this.formCerrarAuditoria.conciliacionMedicamento;
      this.objetoFinalCerrar.fechaCitaPrehospitalaria	= this.formCerrarAuditoria.fechaCitaPrehospitalaria;

      console.log(this.objetoFinalCerrar)
      return this.objetoFinalCerrar
    },
      
    /**
     * Método asincrónico para cerrar una auditoría.
     *
     * Descripción:
     * Este método valida todos los campos obligatorios del formulario de cierre de auditoría (`formCerrarAuditoria`),
     * y si todos los campos son válidos, procede a llamar a `modificarArray2` para preparar los datos del cierre.
     * Posteriormente, realiza una solicitud HTTP al backend para cerrar la auditoría. Si la solicitud es exitosa, muestra
     * un mensaje de éxito y cierra el diálogo de la auditoría. Si ocurre un error, muestra un mensaje de error.
     *
     * Ejemplo de uso:
     * await this.cerrarAuditoria();
     *
     * @returns {void} No retorna nada, ya que realiza una acción asincrónica de cierre de auditoría.
     */
    async cerrarAuditoria() {
      // Validar campos obligatorios
      if (!this.formCerrarAuditoria.tipoEstancia) {
        this.showError("El campo 'Tipo de Estancia' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.fechaEgresoIps) {
        this.showError("El campo 'Fecha de Egreso de la IPS' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.diasHospitalizacion) {
        this.showError("El campo 'Días de Hospitalización' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.mesEgreso) {
        this.showError("El campo 'Mes de Egreso' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.diasInoportunidad) {
        this.showError("El campo 'Días de Inoportunidad' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.motivoInoportunidad) {
        this.showError("El campo 'Motivo de Inoportunidad' es obligatorio."); return;
      }
      if (this.formCerrarAuditoria.condicionEgreso === '') {
        this.showError("El campo 'Condición de Egreso' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.destinoPaciente) {
        this.showError("El campo 'Destino del Paciente' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.sitioRemision) {
        this.showError("El campo 'Sitio de Remisión' es obligatorio."); return;
      }
      if (this.formCerrarAuditoria.facturaPatologia === '') {
        this.showError("El campo 'Factura de Patología' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.idMotivoHospitalizacion) {
        this.showError("El campo 'Motivo de Hospitalización' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.idMotivoUT) {
        this.showError("El campo 'Motivo UT' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.valorCargoUt) {
        this.showError("El campo 'Valor Cargo UT' es obligatorio."); return;
      }
      if (this.formCerrarAuditoria.reporteEventoAdversoSiNo === '') {
        this.showError("El campo 'Reporte de Evento Adverso' es obligatorio."); return;
      }
      // Validación condicional de 'eventoAdverso'
      if (this.formCerrarAuditoria.reporteEventoAdversoSiNo === true && !this.formCerrarAuditoria.eventoAdverso) {
        this.showError("El campo 'Evento Adverso' es obligatorio cuando se reporta un evento adverso."); return;
      }
      if (this.formCerrarAuditoria.infeccion === '') {
        this.showError("El campo 'Infección' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.tasaMortalidad) {
        this.showError("El campo 'Tasa de Mortalidad' es obligatorio."); return;
      }
      if (this.formCerrarAuditoria.polimedicado === '') {
        this.showError("El campo 'Polimedicado' es obligatorio."); return;
      }
      if (this.formCerrarAuditoria.conciliacionMedicamento === '') {
        this.showError("El campo 'Conciliación de Medicamentos' es obligatorio."); return;
      }
      if (!this.formCerrarAuditoria.fechaCitaPrehospitalaria) {
        this.showError("El campo 'Fecha de Cita Prehospitalaria' es obligatorio."); return;
      }

      this.modificarArray2(); 
      // if (this.formNuevaAuditoria.length == this.objetoFinal.cuerpo.length) {
      console.log(this.objetoFinalCerrar)
      let obj = JSON.stringify(this.objetoFinalCerrar);
      //this.formData.append('objetoFinal', new Blob([obj], { type: 'application/json' }));
      this.$http.post("msa-localapp/api/v1/auditoria-concurrente/cerrar-auditoria", obj).then(() => {
        this.showSuccess("Auditoria Cerrada correctamente");
        this.limpiarFormularioCerrar();
        this.dialogCerrarAuditoria = false;
        this.$emit('getAuditorias'); //cargar tabla ppal
      }).catch((error) => {
        console.log("aqui el error en el request")
        console.log(error.response.data.message)
        //this.showError(error.response.data.message[0])
      })
    },

    /**
     * Método para limpiar los campos del formulario de cierre de auditoría.
     *
     * Descripción:
     * Este método reinicia todos los campos del formulario de cierre de auditoría (`formCerrarAuditoria`) a sus valores
     * predeterminados o vacíos. Esto es útil para restablecer el estado del formulario después de cerrar una auditoría
     * o cuando el usuario necesita volver a ingresar los datos.
     *
     * Ejemplo de uso:
     * this.limpiarFormularioCerrar();
     *
     * @returns {void} No retorna nada, ya que solo limpia el formulario.
     */
    limpiarFormularioCerrar() {
        this.formCerrarAuditoria.tipoEstancia = "";
        this.formCerrarAuditoria.fechaEgresoIps = "";
        this.formCerrarAuditoria.diasHospitalizacion = "";
        this.formCerrarAuditoria.diasHospitalizacionOptions = Array.from({ length: 110 }, (_, i) => i + 1);
        this.formCerrarAuditoria.mesEgreso = "";
        this.formCerrarAuditoria.diasInoportunidad = "";
        this.formCerrarAuditoria.diasInoportunidadOptions = Array.from({ length: 110 }, (_, i) => i + 1);
        this.formCerrarAuditoria.motivoInoportunidad = "";
        this.formCerrarAuditoria.condicionEgreso = "";
        this.formCerrarAuditoria.destinoPaciente = "";
        this.formCerrarAuditoria.sitioRemision = "";
        this.formCerrarAuditoria.facturaPatologia = "";
        this.formCerrarAuditoria.idMotivoHospitalizacion = "";
        this.formCerrarAuditoria.opcionesMotivoHospitalizacion = []; 
        this.formCerrarAuditoria.idMotivoUT = "";
        this.formCerrarAuditoria.opcionesMotivoUt = []; 
        this.formCerrarAuditoria.valorCargoUt = "";
        this.formCerrarAuditoria.reporteEventoAdversoSiNo = "";
        this.formCerrarAuditoria.eventoAdverso = "";
        this.formCerrarAuditoria.infeccion = "";
        this.formCerrarAuditoria.tasaMortalidad = "";
        this.formCerrarAuditoria.polimedicado = "";
        this.formCerrarAuditoria.conciliacionMedicamento = "";
        this.formCerrarAuditoria.fechaCitaPrehospitalaria = "";
    }
      
  },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
  ::v-deep .v-toolbar__content {
    justify-content: space-between !important;
  }

  ::v-deep .tableResults div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
  }

  ::v-deep .tableResults div table thead tr th span {
    font-weight: bold;
    color: black !important;
  }

  th {
    color: black !important;
  }

  ::v-deep .tableResults div {
    --sb-track-color: #d1cfcf;
    --sb-thumb-color: #8b8585;
    --sb-size: 8px;
  }

  ::v-deep .tableResults div::-webkit-scrollbar {
    width: var(--sb-size)
  }

  ::v-deep .v-messages {
    display: none;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .tableResults div::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 6px;
  }

  ::v-deep .tableResults div::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 6px;
  }

  ::v-deep .v-input__prepend-outer {
    display: none;
  }

  ::v-deep .v-input__icon--prepend {
    display: none;
  }

  .color-red {
    color: red; /* Define el color rojo */
  }

  .v-card-text{
    margin-top: 20px
  }
</style>
